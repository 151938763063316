import {
  UseMutationResult,
  UseQueryResult,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import { useTournamentsQuery } from '@/generated';
import client from '@/utils/api';
import {
  PlayerTournament,
  PlayerTournamentStatus,
  Tournament,
} from '@/types/tournaments';
import { useAuth } from '../auth';
import { DEFAULT_CONTENT_STALE_TIME, GENERAL_ERROR } from '@/utils/constants';
import { fetchTournamentIdDetails } from '@/utils/tournaments';
import { useToast } from '@purposeinplay/utils';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import { useTranslation } from '@/app/i18n/client';
import { useOtherToastMessages } from '@/hooks/use-other-toast-messages';

export const useTournaments = (options = {}): UseQueryResult<Tournament[]> => {
  return useQuery({
    queryKey: ['tournamentsAPI'],
    queryFn: () => client('tournaments'),
    staleTime: 1 * 60 * 1000,
    refetchOnMount: 'always',
    ...options,
  });
};

export const useTournament = (tournamentId: string) => {
  return useQuery({
    queryKey: ['fetchTournament', { tournament: tournamentId }],
    queryFn: ({ queryKey }: any) =>
      fetchTournamentIdDetails({ tournament: queryKey[1]?.tournament }),
    staleTime: 3 * 60 * 1000,
  });
};

export const useRecentTournaments = (): UseQueryResult<Tournament[]> => {
  return useQuery({
    queryKey: ['recentTournaments'],
    queryFn: () => client('tournaments/recent'),
  });
};

export const usePlayerTournaments = (): UseQueryResult<PlayerTournament[]> => {
  const {
    user: { isAuthenticated },
  }: any = useAuth();

  return useQuery({
    queryKey: ['playerTournaments'],
    queryFn: () => client('tournaments/statuses'),
    enabled: isAuthenticated ? true : false,
  });
};

export const useEnrollPlayer = (): UseMutationResult => {
  const { t } = useTranslation();
  const toastMessages = useOtherToastMessages();
  const { toast } = useToast();
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['enrollPlayer'],
    mutationFn: (data: any) => {
      const { tournamentId, enroll = true } = data;
      try {
        return client(
          `tournaments/${tournamentId}/confirm`,
          {
            confirmed: enroll,
          },
          'POST',
        );
      } catch (e) {
        throw e;
      }
    },
    onSettled: () => {
      toast({
        state: 'success',
        title: toastMessages?.tournaments_enrolled,
        autoClose: 1500,
      });
      queryClient.invalidateQueries({ queryKey: ['playerTournaments'] });
    },
    onError: () => {
      toast({
        state: 'error',
        title: toastMessages?.general_error,
        autoClose: 1500,
      });
    },
  });
};

export const usePlayerTournamentStatus = (
  tournamentId: string | number,
): UseQueryResult<PlayerTournamentStatus> => {
  const {
    user: { isAuthenticated },
  }: any = useAuth();

  return useQuery({
    queryKey: ['playerTournamentStatus', tournamentId],
    queryFn: () => client(`tournaments/${tournamentId}/status`),
    enabled: isAuthenticated ? true : false,
  });
};

export const useVipTournament = () => {
  const { data: tournaments = [], isPending: areTournamentsFetching } =
    useTournaments();

  const { data: tournamentsCMS, isPending: cmsPending } = useTournamentsQuery(
    undefined,
    {
      staleTime: DEFAULT_CONTENT_STALE_TIME,
    },
  );

  const now = dayjs();

  const tournamentsList = Array.from(tournaments);

  const filteredTournament = useMemo((): any => {
    const foundTournament: any =
      tournamentsList?.find(
        (tournament: any) =>
          tournament?.frontend_identifier?.includes('vip-tournament') && // filter tournaments that are in progress or will start soon
          (tournament?.in_progress ||
            now.diff(dayjs(tournament.start_at)) < 0 ||
            !tournament.finished_at),
      ) || {};
    const cmsTournament =
      tournamentsCMS?.tournaments?.data.find(
        (content) =>
          content?.attributes?.uid === foundTournament?.frontend_identifier,
      ) || {};
    return { ...foundTournament, ...cmsTournament?.attributes };
  }, [now, tournamentsCMS?.tournaments?.data, tournamentsList]);
  return {
    tournament: filteredTournament,
    isLoading: areTournamentsFetching || cmsPending,
  };
};
